import { useMutation } from "@tanstack/react-query";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink, useNavigate } from "react-router-dom";

import register, { RegisterRequest } from "@src/api/auth/register";
import Alert from "@src/components/elements/Alert";
import VerifyBadge from "@src/components/elements/VerifyBadge";
import Button from "@src/components/elements/input/Button";
import Checkbox from "@src/components/elements/input/Checkbox";
import CustomSelect from "@src/components/elements/input/CustomSelect";
import Input from "@src/components/elements/input/Input";
import AppLink from "@src/components/elements/shared/AppLink";
import BackButton from "@src/components/nav/BackButton";
import AuthWrapper from "@src/pages/auth/AuthWrapper";
import usePopupModal from "@src/state/modal/usePopupModal";
import trackFormSubmission from "@src/utils/trackFormSubmission";

const types = [
  {
    name: "Persönliches Profil",
    value: "person",
    icon: <VerifyBadge customStyle="my-0.5" h="h-4" w="w-4" type="person" />,
  },
  {
    name: "Unternehmen",
    value: "company",
    icon: <VerifyBadge customStyle="my-0.5" h="h-4" w="w-4" type="company" />,
  },
  {
    name: "Verein",
    value: "club",
    icon: <VerifyBadge customStyle="my-0.5" h="h-4" w="w-4" type="club" />,
  },
];

type Props = {
  initType?: number;
};

const RegisterContainer = ({ initType = 0 }: Props) => {
  const { t } = useTranslation();
  const [email, setEmail] = useState<string>("");
  const [type, setType] = useState<string>(types[initType].value);
  const [password, setPassword] = useState<string>("");
  const [name, setName] = useState<string>("");
  const [firstname, setFirstname] = useState<string>("");
  const [lastname, setLastname] = useState<string>("");
  const [agb, setAgb] = useState(false);
  const [newsletter, setNewsletter] = useState(false);
  const popupModal = usePopupModal();
  const navigate = useNavigate();

  const registerMutation = useMutation({
    mutationKey: ["register"],
    mutationFn: (request: RegisterRequest) => {
      trackFormSubmission("registerForm");
      return register(request);
    },
    onSuccess: async () => {
      popupModal.open(
        <Alert
          buttons={Alert.ButtonVariants.RetryOK}
          variant={Alert.MessageVariants.OK}
          onClose={popupModal.close}
          retryClick={() => {
            console.log("register");
          }}
          yesClick={() => {
            navigate("/news/für_dich");
          }}
          retryLabel={t("main.alerts.registerEmailRetry")}
          message={t("main.alerts.registerSuccess.message.pendingVerbose") + "\n\n" + email}
          title={t("main.header.links.register")}
          timeout={-1}
          windowStyle="lg:max-w-[600px]"
        />,
      );
    },
    onError: async (error: Error) => {
      popupModal.open(
        <Alert
          buttons={Alert.ButtonVariants.OK}
          variant={Alert.MessageVariants.Error}
          onClose={popupModal.close}
          message={t("main.alerts.registerError") + error.message}
          title={t("main.header.links.register")}
        />,
      );
    },
  });

  return (
    <AuthWrapper>
      <BackButton stepback desktopVisible />
      <h1>TRADEFOOX {t("main.header.links.register")}</h1>
      <button
        onClick={() => {
          popupModal.open(
            <Alert
              buttons={Alert.ButtonVariants.RetryOK}
              variant={Alert.MessageVariants.OK}
              onClose={popupModal.close}
              retryClick={() => {
                console.log("register");
              }}
              retryLabel={t("main.alerts.registerEmailRetry")}
              message={t("main.alerts.registerSuccess.message.pendingVerbose") + "\n\n" + email}
              title={t("main.header.links.register")}
              timeout={-1}
              windowStyle="lg:max-w-[600px]"
            />,
          );
        }}
      >
        {t("components.shared.start")}
      </button>
      <form
        id="registerForm"
        onSubmit={e => {
          e.preventDefault();
        }}
      >
        <CustomSelect
          defaultValue={types[initType].value}
          label={t("main.signIn.registerAs")}
          onChange={value => setType(value)}
          options={types}
        />
        {type === "person" ? (
          <div className="grid grid-cols-2 gap-2">
            <Input
              name="firstname"
              label={t("main.profileViewTabs.editProfile.form.firstName")}
              value={firstname}
              placeholder="Max"
              type="text"
              minLength={3}
              maxLength={32}
              required={true}
              onChange={e => setFirstname(e)}
              title={t("main.profileViewTabs.editProfile.form.atleast")}
            />
            <Input
              name="lastname"
              label={t("main.profileViewTabs.editProfile.form.lastName")}
              value={lastname}
              placeholder="Mustermann"
              type="text"
              minLength={3}
              maxLength={32}
              required={true}
              onChange={e => setLastname(e)}
              title={t("main.profileViewTabs.editProfile.form.atleast")}
            />
          </div>
        ) : (
          <Input
            name="name"
            label={
              type === "club"
                ? t("components.shared.nameOfClub")
                : t("components.shared.nameOfCompany")
            }
            value={name}
            placeholder={
              type === "club"
                ? t("components.shared.exampleClub")
                : t("components.shared.exampleCompany")
            }
            type="text"
            minLength={3}
            maxLength={32}
            required={true}
            onChange={e => setName(e)}
            title={t("main.profileViewTabs.editProfile.form.atleast")}
          />
        )}
        <Input
          name="email"
          label={t("main.signIn.email")}
          value={email}
          placeholder="max.mustermann@beispiel.de"
          type="email"
          onChange={e => setEmail(e)}
          required={true}
          title={t("main.panel.profileWidgets.validEmail")}
        />
        <Input
          name="password"
          label={t("main.passwordChange.label")}
          value={password}
          placeholder={t("main.passwordChange.label")}
          type="password"
          minLength={8}
          maxLength={32}
          pattern="^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?!.*\s).*$"
          onChange={e => setPassword(e)}
          required={true}
          title={t("main.passwordChange.requiredSigns")}
        />
        <div className="my-3">
          <Checkbox
            name="privacy"
            checked={agb}
            onChange={checked => setAgb(checked)}
            required={true}
          >
            {t("main.signIn.accept")}{" "}
            <NavLink to="/datenschutzrichtlinie" className="text-darkcyan">
              {t("main.signIn.dataProtectionLines")}
            </NavLink>{" "}
            {t("components.shared.and")}{" "}
            <NavLink to="/agb" className="text-darkcyan">
              {t("main.signIn.userAgreement")}
            </NavLink>
            .
          </Checkbox>
          <Checkbox
            name="newsletter"
            checked={newsletter}
            onChange={checked => setNewsletter(checked)}
          >
            {t("components.shared.acceptNewsletter")}
          </Checkbox>
        </div>
        <Button
          disabled={registerMutation.isPending}
          submit
          onClick={() =>
            !registerMutation.isPending &&
            registerMutation.mutate({
              data: {
                type,
                email,
                password,
                firstname,
                lastname,
                name,
              },
            })
          }
        >
          {t("main.signIn.createAccount")}
        </Button>
      </form>
      <div className="mt-2 grid grid-cols-1 text-center">
        <NavLink to="/login">{t("pages.register.gotAccount")}</NavLink>
        <NavLink to="/verwaltung/passwort">{t("main.signIn.forgotPassword")}</NavLink>
      </div>
      <AppLink />
      <div className="text-center">
        <NavLink to="/impressum" className="text-sm">
          {t("main.signIn.imprint")}
        </NavLink>
      </div>
    </AuthWrapper>
  );
};

export default RegisterContainer;
