import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import Button from "@src/components/elements/input/Button";
import { OrderedList, UnorderedList } from "@src/components/elements/shared/ListComponents";

interface PackageCardProps {
  title: string;
  price: string;
  subtitle: string;
  features: string[];
  buttonLabel: string;
  link?: string;
  priceLabel?: string;
  theme?: "red" | "green" | "none";
}

const PackageCard: React.FC<PackageCardProps> = ({
  theme = "none",
  title,
  price,
  subtitle,
  features,
  buttonLabel,
  link = "",
  priceLabel,
}) => {
  const themes = {
    red: {
      borderStyle: "border-[3px] border-red-600",
      titleStyle: "bg-red-600 text-center text-gray-50",
      buttonStyle: Button.Variants.White,
    },
    green: {
      borderStyle: "border-[3px] border-green-600",
      titleStyle: "bg-green-600 text-center text-gray-50",
      buttonStyle: Button.Variants.Green,
    },
    none: {
      borderStyle: "border-[3px] border-gray-100",
      titleStyle: "border-b-[3px] text-center border-gray-100",
      buttonStyle: Button.Variants.White,
    },
  };

  return (
    <div
      className={`flex size-[500px] max-w-[25wh] flex-col justify-between ${themes[theme].borderStyle}`}
    >
      <div>
        <h1 className={`mb-6 ${themes[theme].titleStyle}`}>{title}</h1>
        <div className="px-6">
          <div className="h-[110px]">
            <div className="flex flex-wrap items-end gap-x-1">
              <div className="text-4xl">{price}</div>
              <div>{priceLabel}</div>
            </div>
            <div>{subtitle}</div>
          </div>
          <UnorderedList
            items={features}
            symbol="✅"
            className="flex flex-col gap-1.5"
            gap="gap-x-1"
          />
        </div>
      </div>
      <div className="p-6">
        <Link to={link}>
          <Button variant={themes[theme].buttonStyle} size={Button.Sizes.Large}>
            {buttonLabel}
          </Button>
        </Link>
      </div>
    </div>
  );
};

export const SellerPackages = () => {
  const { t } = useTranslation();

  return (
    <>
      <PackageCard
        theme="red"
        price={t("main.marketplace.seller.packages.premium.price")}
        priceLabel={t("main.marketplace.seller.packages.taxLabel")}
        buttonLabel={t("main.marketplace.seller.packages.premium.action")}
        subtitle={t("main.marketplace.seller.packages.premium.subtitle")}
        title={t("main.marketplace.seller.packages.premium.title")}
        features={[
          t("main.marketplace.seller.packages.premium.benefits.text01"),
          t("main.marketplace.seller.packages.premium.benefits.text02"),
          t("main.marketplace.seller.packages.premium.benefits.text03"),
          t("main.marketplace.seller.packages.premium.benefits.text04"),
          t("main.marketplace.seller.packages.premium.benefits.text05"),
          t("main.marketplace.seller.packages.premium.benefits.text06"),
          t("main.marketplace.seller.packages.premium.benefits.text07"),
        ]}
      />
      <PackageCard
        link="/registrierung"
        theme="green"
        price={t("main.marketplace.seller.packages.free.price")}
        priceLabel=""
        buttonLabel={t("main.marketplace.seller.packages.free.action")}
        subtitle={t("main.marketplace.seller.packages.free.subtitle")}
        title={t("main.marketplace.seller.packages.free.title")}
        features={[
          t("main.marketplace.seller.packages.free.benefits.text01"),
          t("main.marketplace.seller.packages.free.benefits.text02"),
          t("main.marketplace.seller.packages.free.benefits.text03"),
          t("main.marketplace.seller.packages.free.benefits.text04"),
          t("main.marketplace.seller.packages.free.benefits.text05"),
          t("main.marketplace.seller.packages.free.benefits.text06"),
          t("main.marketplace.seller.packages.free.benefits.text07"),
        ]}
      />
      <PackageCard
        theme="none"
        price={t("main.marketplace.seller.packages.basic.price")}
        priceLabel={t("main.marketplace.seller.packages.taxLabel")}
        buttonLabel={t("main.marketplace.seller.packages.basic.action")}
        subtitle={t("main.marketplace.seller.packages.basic.subtitle")}
        title={t("main.marketplace.seller.packages.basic.title")}
        features={[
          t("main.marketplace.seller.packages.basic.benefits.text01"),
          t("main.marketplace.seller.packages.basic.benefits.text02"),
          t("main.marketplace.seller.packages.basic.benefits.text03"),
          t("main.marketplace.seller.packages.basic.benefits.text04"),
          t("main.marketplace.seller.packages.basic.benefits.text05"),
        ]}
      />
    </>
  );
};

const SellerContainer = () => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col items-center gap-8 pb-6">
      <div className="grid w-full grid-cols-[700px,500px] justify-center">
        <div>
          <div className="mb-4 font-semibold">
            <div>{t("main.marketplace.seller.title.text01")}</div>
            <div>{t("main.marketplace.seller.title.text02")}</div>
            <div>{t("main.marketplace.seller.title.text03")}</div>
          </div>
          <h2>{t("main.marketplace.seller.title.text04")}</h2>
          <div>{t("main.marketplace.seller.title.text05")}</div>
          <div className="my-1 mt-4 font-semibold">
            {t("main.marketplace.seller.benefits.title")}
          </div>
          <UnorderedList
            items={[
              <div className="flex gap-1" key="title-text1">
                <strong>{t("main.marketplace.seller.benefits.text01.1")}</strong>
                <div>{t("main.marketplace.seller.benefits.text01.2")}</div>
              </div>,
              <div className="flex gap-1" key="title-text2">
                <div>{t("main.marketplace.seller.benefits.text02.1")}</div>
                <strong>6.000</strong>
                <div>{t("main.marketplace.seller.benefits.text02.2")}</div>
              </div>,
              t("main.marketplace.seller.benefits.text03"),
              t("main.marketplace.seller.benefits.text04"),
              t("main.marketplace.seller.benefits.text05"),
              t("main.marketplace.seller.benefits.text06"),
              t("main.marketplace.seller.benefits.text07"),
              t("main.marketplace.seller.benefits.text08"),
            ]}
          />
        </div>
        <div
          className="relative ml-8 h-[450px] w-[500px]"
          style={{
            backgroundImage: "url('/images/europe_map.png')",
            backgroundSize: "cover",
            boxShadow: "0 0 30px 50px white inset",
          }}
        />
      </div>
      <h1 className="mb-6 text-center">{t("main.marketplace.seller.international")}</h1>
      <div className="flex flex-row justify-center gap-24">
        <div className="flex flex-col items-center">
          <img alt="Tradefoox" src="/logo512.png" className="mb-2 size-16" />
          <div>TRADEFOOX.de</div>
          <div>dein Marktplatz für den DACH-Raum</div>
        </div>
        <div className="flex flex-col items-center">
          <img alt="Tradefoox" src="/logo512.png" className="mb-2 size-16" />
          <div>TRADEFOOX.sk</div>
          <div>online trhovisko</div>
        </div>
        <div className="flex flex-col items-center">
          <img alt="Tradefoox" src="/logo512.png" className="mb-2 size-16" />
          <div>TRADEFOOX.cz</div>
          <div>váš marketplace</div>
        </div>
        <div className="flex flex-col items-center">
          <img alt="Tradefoox" src="/logo512.png" className="mb-2 size-16" />
          <div>TRADEFOOX.pl</div>
          <div>Twój marketplace</div>
        </div>
        <div className="flex flex-col items-center">
          <img alt="Tradefoox" src="/logo512.png" className="mb-2 size-16" />
          <div>TRADEFOOX.at</div>
          <div>für den österreichischen Markt</div>
        </div>
      </div>
      <h1 className="text-center">{t("main.marketplace.seller.packages.title")}</h1>
      <div className="flex flex-wrap justify-center gap-24">
        <SellerPackages />
      </div>
      <Link className="text-3xl text-red-600 underline" to="/marketplace/seller/bedingungen">
        {t("main.marketplace.seller.provisionsLink")}
      </Link>
      <h1 className="text-center">{t("main.marketplace.seller.faq.title")}</h1>
      <OrderedList
        items={[
          <div className="flex flex-wrap gap-1" key="faq-register">
            <strong>{t("main.marketplace.seller.faq.text01.1")}</strong>
            <div>{t("main.marketplace.seller.faq.text01.2")}</div>
          </div>,
          <div className="flex flex-wrap gap-1" key="faq-offers">
            <strong>{t("main.marketplace.seller.faq.text02.1")}</strong>
            <div>{t("main.marketplace.seller.faq.text02.2")}</div>
          </div>,
          <div className="flex flex-wrap gap-1" key="faq-sell">
            <strong>{t("main.marketplace.seller.faq.text03.1")}</strong>
            <div>{t("main.marketplace.seller.faq.text03.2")}</div>
          </div>,
          <div className="flex flex-wrap gap-1" key="faq-support">
            <strong>{t("main.marketplace.seller.faq.text04.1")}</strong>
            <div>{t("main.marketplace.seller.faq.text04.2")}</div>
          </div>,
        ]}
      />
      <h1>{t("main.marketplace.seller.testimonials.title")}</h1>
      <div className="flex flex-col gap-4">
        <div>
          <div>{t("main.marketplace.seller.testimonials.sample1.1")}</div>
          <div>{t("main.marketplace.seller.testimonials.sample1.2")}</div>
        </div>
        <div>
          <div>{t("main.marketplace.seller.testimonials.sample2.1")}</div>
          <div>{t("main.marketplace.seller.testimonials.sample2.2")}</div>
        </div>
      </div>
      <div className="text-center">
        <h1 className="text-center">{t("main.marketplace.seller.callToAction.title")}</h1>
        <h1>{t("main.marketplace.seller.callToAction.text01")}</h1>
        <div>{t("main.marketplace.seller.callToAction.text02")}</div>
      </div>
      <Link to="/registrierung/unternehmen">
        <Button fitWidth variant={Button.Variants.Primary} size={Button.Sizes.Large}>
          {t("main.marketplace.seller.callToAction.text03")}
        </Button>
      </Link>
    </div>
  );
};

export default SellerContainer;
