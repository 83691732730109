import { useTranslation } from "react-i18next";

import { UnorderedList } from "@src/components/elements/shared/ListComponents";
import { SellerPackages } from "@src/pages/seller/SellerContainer";

const SellerConditionsContainer = () => {
  const { t } = useTranslation();

  const categoryTable = [
    [t("main.marketplace.sellerConditions.categoryTable.td01"), "7 %*", "0 %"],
    [t("main.marketplace.sellerConditions.categoryTable.td02"), "7 %*", "0 %"],
    [t("main.marketplace.sellerConditions.categoryTable.td03"), "10 %*", "0 %"],
    [t("main.marketplace.sellerConditions.categoryTable.td04"), "10 %*", "0 %"],
    [t("main.marketplace.sellerConditions.categoryTable.td05"), "13 %*", "0 %"],
    [t("main.marketplace.sellerConditions.categoryTable.td06"), "13 %*", "0 %"],
    [t("main.marketplace.sellerConditions.categoryTable.td07"), "13 %*", "0 %"],
    [t("main.marketplace.sellerConditions.categoryTable.td08"), "13 %*", "0 %"],
    [
      t("main.marketplace.sellerConditions.categoryTable.td09"),
      t("main.marketplace.sellerConditions.perArticle", {
        numbers: "13 %* + 0,70 €",
      }),
      t("main.marketplace.sellerConditions.perArticle", {
        numbers: "0 % + 0,00 €",
      }),
    ],
    [t("main.marketplace.sellerConditions.categoryTable.td10"), "14 %*", "0 %"],
    [t("main.marketplace.sellerConditions.categoryTable.td11"), "14 %*", "0 %"],
    [t("main.marketplace.sellerConditions.categoryTable.td12"), "16 %*", "0 %"],
    [t("main.marketplace.sellerConditions.categoryTable.td13"), "13 %*", "0 %"],
  ];

  // t("main.marketplace.sellerConditions.exampleTable.td01.1"),
  // t("main.marketplace.sellerConditions.exampleTable.td01.2"),
  // t("main.marketplace.sellerConditions.exampleTable.td02.1"),
  // t("main.marketplace.sellerConditions.exampleTable.td02.2"),
  // t("main.marketplace.sellerConditions.exampleTable.td03.1"),
  // t("main.marketplace.sellerConditions.exampleTable.td03.2"),
  // t("main.marketplace.sellerConditions.exampleTable.td04.1"),
  // t("main.marketplace.sellerConditions.exampleTable.td04.2"),
  // t("main.marketplace.sellerConditions.exampleTable.td05.1"),
  // t("main.marketplace.sellerConditions.exampleTable.td05.2"),
  // t("main.marketplace.sellerConditions.exampleTable.td06.1"),
  // t("main.marketplace.sellerConditions.exampleTable.td06.2"),
  // t("main.marketplace.sellerConditions.exampleTable.td07.1"),
  // t("main.marketplace.sellerConditions.exampleTable.td07.2"),
  // t("main.marketplace.sellerConditions.exampleTable.td08.1"),
  // t("main.marketplace.sellerConditions.exampleTable.td08.2"),
  // t("main.marketplace.sellerConditions.exampleTable.td09.1"),
  // t("main.marketplace.sellerConditions.exampleTable.td09.2"),
  // t("main.marketplace.sellerConditions.exampleTable.td10.1"),
  // t("main.marketplace.sellerConditions.exampleTable.td10.2"),
  // t("main.marketplace.sellerConditions.exampleTable.td11.1"),
  // t("main.marketplace.sellerConditions.exampleTable.td11.2"),
  // t("main.marketplace.sellerConditions.exampleTable.td12.1"),
  // t("main.marketplace.sellerConditions.exampleTable.td12.2"),

  const rows = Array.from({ length: 12 }, (_, i) => i + 1);
  const exampleTable = rows.map(row => [
    t(`main.marketplace.sellerConditions.exampleTable.td${String(row).padStart(2, "0")}.1`),
    t(`main.marketplace.sellerConditions.exampleTable.td${String(row).padStart(2, "0")}.2`),
  ]);

  return (
    <div className="flex flex-col items-center gap-8 pb-6">
      <h1>{t("main.marketplace.sellerConditions.categoryTable.title")}</h1>
      <div>
        <div>
          {t("main.marketplace.sellerConditions.categoryTable.text1.1")}
          <strong>{t("main.marketplace.sellerConditions.categoryTable.text1.2")}</strong>
          {t("main.marketplace.sellerConditions.categoryTable.text1.3")}
          <strong>{t("main.marketplace.sellerConditions.categoryTable.text1.4")}</strong>
        </div>
        <div>
          {t("main.marketplace.sellerConditions.categoryTable.text2.1")}
          <strong>{t("main.marketplace.sellerConditions.categoryTable.text2.2")}</strong>
        </div>
        {t("main.marketplace.sellerConditions.categoryTable.text3")}
      </div>
      <h2>{t("main.marketplace.sellerConditions.categoryTable.text4")}</h2>
      <table className="w-full table-auto border border-gray-300 text-left text-sm lg:max-w-[65vw]">
        <thead className="bg-gray-100">
          <tr>
            <th className="border px-4 py-2 font-semibold">
              {t("main.marketplace.sellerConditions.categoryTable.th1")}
            </th>
            <th className="border px-4 py-2 font-semibold">
              {t("main.marketplace.sellerConditions.categoryTable.th2")}
            </th>
            <th className="border px-4 py-2 font-semibold">
              {t("main.marketplace.sellerConditions.categoryTable.th3")}
            </th>
          </tr>
        </thead>
        <tbody>
          {categoryTable.map((row, rowIndex) => (
            <tr key={`categories-${rowIndex.toString()}`} className="odd:bg-white even:bg-gray-50">
              {row.map((cell, cellIndex) => (
                <td
                  key={`categories-${rowIndex.toString()}-${cellIndex.toString()}`}
                  className="border px-4 py-2"
                >
                  {cell}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      <h2>{t("main.marketplace.sellerConditions.exampleTable.title")}</h2>
      <table className="w-full table-auto border border-gray-300 text-left text-sm lg:max-w-[65vw]">
        <thead className="bg-gray-100">
          <tr>
            <th className="border px-4 py-2 font-semibold">
              {t("main.marketplace.sellerConditions.exampleTable.th1")}
            </th>
            <th className="border px-4 py-2 font-semibold">
              {t("main.marketplace.sellerConditions.exampleTable.th2")}
            </th>
          </tr>
        </thead>
        <tbody>
          {exampleTable.map((row, rowIndex) => (
            <tr key={`categories-${rowIndex.toString()}`} className="odd:bg-white even:bg-gray-50">
              {row.map((cell, cellIndex) => (
                <td
                  key={`categories-${rowIndex.toString()}-${cellIndex.toString()}`}
                  className="border px-4 py-2"
                >
                  {cell}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      <div>
        <h1>{t("main.marketplace.sellerConditions.benefits.title")}</h1>
        <UnorderedList
          items={[
            t("main.marketplace.sellerConditions.benefits.text1"),
            t("main.marketplace.sellerConditions.benefits.text2"),
            t("main.marketplace.sellerConditions.benefits.text3"),
            t("main.marketplace.sellerConditions.benefits.text4"),
            t("main.marketplace.sellerConditions.benefits.text5"),
          ]}
        />
      </div>
      <div className="flex flex-wrap justify-center gap-24">
        <SellerPackages />
      </div>
    </div>
  );
};

export default SellerConditionsContainer;
