import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

type Props = {
  center?: boolean;
};

const InvestorsLink = ({ center = true }: Props) => {
  const { t } = useTranslation();

  return (
    <div className={`flex ${center && "justify-center gap-2"}`}>
      <Link to="/investoren">
        <img
          src="/images/button_investors.png"
          alt={t("buttons.invest")}
          className="h-8 w-[100px]"
        />
      </Link>
      <Link to="/spenden">
        <img src="/images/button_donate.png" alt={t("buttons.donate")} className="h-8 w-[100px]" />
      </Link>
    </div>
  );
};

export default InvestorsLink;
