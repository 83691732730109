import { useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink, useNavigate } from "react-router-dom";

import Panel from "@src/components/box/Panel";
import Button from "@src/components/elements/input/Button";
import Input from "@src/components/elements/input/Input";
import AppLink from "@src/components/elements/shared/AppLink";
import InvestorsLink from "@src/components/elements/shared/InvestorsLink";
import LoginExternal from "@src/components/popup/LoginExternal";
import useLoginMutation from "@src/utils/useLoginMutation";

const LoginContainer = () => {
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const navigate = useNavigate();
  const { t } = useTranslation();
  const loginMutation = useLoginMutation();

  return (
    <Panel mobileBorder={false}>
      <form
        onSubmit={e => {
          e.preventDefault();
        }}
        id="loginForm"
        className="grid grid-flow-row justify-center gap-1 text-center text-black"
      >
        <div className="text-xl font-medium text-darkblue">
          {t("main.signIn.title1")}
          <br /> {t("main.signIn.title2")}
        </div>
        <Input
          name="email"
          label={t("main.signIn.email")}
          value={email}
          placeholder={t("main.signIn.placeholderEmail")}
          type="email"
          onChange={e => setEmail(e)}
        />
        <Input
          name="password"
          label={t("main.signIn.password")}
          value={password}
          placeholder={t("main.signIn.password")}
          type="password"
          onChange={e => setPassword(e)}
        />
        <Button
          submit
          size={Button.Sizes.Large}
          onClick={() =>
            !loginMutation.isPending &&
            loginMutation.mutate({
              data: {
                email,
                password,
              },
            })
          }
        >
          {t("main.signIn.login")}
        </Button>
        <LoginExternal />
        <div className="flex flex-row items-center justify-center gap-1 text-darkblue">
          <div className="text-3xl">!</div>
          <div className="whitespace-nowrap text-mini">
            {t("main.signIn.forgotPassword")} <br />
            {t("main.signIn.resetPassword")}{" "}
            <NavLink to="/verwaltung/passwort" className="text-darkcyan">
              {t("main.signIn.here")}
            </NavLink>{" "}
            {t("main.signIn.newPassword")}
          </div>
        </div>
        <div className="text-sm text-gray-700">{t("main.signIn.description")}</div>
        <Button
          size={Button.Sizes.Large}
          onClick={() => {
            navigate("/registrierung");
          }}
          variant={Button.Variants.Cyan}
        >
          {t("main.signIn.createAccount")}
        </Button>
        <AppLink />
        <div className="flex justify-center gap-2">
          <NavLink to="/impressum" className="text-sm">
            {t("main.signIn.imprint")}
          </NavLink>
          <span className="text-sm">|</span>
          <NavLink to="/datenschutz" className="text-sm">
            {t("main.signIn.dataProtection")}
          </NavLink>
        </div>
        <InvestorsLink />
      </form>
    </Panel>
  );
};

export default LoginContainer;
