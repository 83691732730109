import { DocumentTextIcon, EyeIcon, PlayIcon } from "@heroicons/react/24/outline";
import { HeartIcon } from "@heroicons/react/24/solid";
import { useRef, useState } from "react";
import ReactPlayer from "react-player";

import ReactPlayerNavLink from "@src/components/elements/ReactPlayerNavLink";

type Props = {
  key?: string;
  link: string;
  type?: "video" | "image";
  url?: string;
  viewCount?: number;
  likeCount?: number;
  title?: string;
  tag?: string;
  authorName?: string;
  authorAvatar?: string;
  previewOnly?: boolean;
};

const CompactPost = ({
  link,
  type,
  url,
  viewCount,
  likeCount,
  title,
  tag,
  authorAvatar,
  authorName,
  previewOnly,
}: Props) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const ref = useRef<ReactPlayer>(null);

  return (
    <ReactPlayerNavLink
      to={link}
      className="group relative aspect-[4/5] bg-gray-300 lg:rounded-lg"
      playerRef={ref}
      onPause={() => setIsPlaying(false)}
      onResume={() => setIsPlaying(false)}
      onPlay={() => setIsPlaying(true)}
      onFailure={() => setIsPlaying(false)}
    >
      {type === "video" && !previewOnly ? (
        <div className="video-cover h-full object-cover lg:overflow-hidden lg:rounded-lg">
          <ReactPlayer
            url={`${url}/playlist.m3u8`}
            height="100%"
            width="100%"
            controls={false}
            muted
            playing={isPlaying}
            loop
            responsive
            ref={ref}
          />
        </div>
      ) : (
        <img
          alt=""
          src={url}
          className="mx-auto size-full object-cover object-center lg:rounded-lg"
          onError={({ currentTarget }) => {
            currentTarget.onerror = null;
            currentTarget.src = "/placeholder.png";
          }}
        />
      )}
      {type === "video" ? (
        <PlayIcon className="absolute right-1 top-1 size-5" />
      ) : (
        <DocumentTextIcon className="absolute right-1 top-1 size-5" />
      )}
      <div className="invisible absolute top-0 size-full bg-black/30 group-hover:visible">
        <div className="absolute top-1/2 w-full -translate-y-1/2 px-2">
          {viewCount != undefined && (
            <div className="flex flex-row items-center justify-center gap-1 text-white">
              <EyeIcon className="flip size-5 -scale-x-100 text-white" />
              <div>{viewCount}</div>
            </div>
          )}
          {likeCount != undefined && (
            <div className="flex flex-row items-center justify-center gap-1 font-semibold text-white">
              <HeartIcon className="size-5" />
              <div>{likeCount}</div>
            </div>
          )}
          {title && (
            <div className="flex flex-row items-center justify-center gap-1 text-white">
              <div className="line-clamp-1 text-center">{title}</div>
            </div>
          )}
          {tag && (
            <div className="flex flex-row items-center justify-center gap-1 text-white">
              <div>#{tag}</div>
            </div>
          )}
          {authorAvatar && (
            <div className="flex flex-row items-center justify-center gap-1 text-white">
              <img alt="" src={authorAvatar} className="size-4 rounded-full" />
              <div className="truncate text-center">{authorName}</div>
            </div>
          )}
        </div>
      </div>
    </ReactPlayerNavLink>
  );
};

export default CompactPost;
