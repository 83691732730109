import { ReactNode } from "react";

interface OrderedListProps {
  items: ReactNode[];
  className?: string;
  gap?: string;
}

interface UnorderedListProps extends OrderedListProps {
  symbol?: string;
}

export const OrderedList = ({
  items,
  className = "list-inside list-decimal text-justify",
  gap,
}: OrderedListProps) => {
  return (
    <ol className={className}>
      {items.map((item, index) => (
        <li className={`flex flex-wrap items-start ${gap}`} key={`${index.toString()}`}>
          {item}
        </li>
      ))}
    </ol>
  );
};

export const UnorderedList = ({
  items,
  className = "list-inside list-disc text-justify",
  symbol,
  gap,
}: UnorderedListProps) => {
  return (
    <ul className={className}>
      {items.map((item, index) => (
        <li className={`flex flex-wrap items-start ${gap}`} key={`${index.toString()}`}>
          {symbol && <span className="mr-2">{symbol}</span>}
          <span>{item}</span>
        </li>
      ))}
    </ul>
  );
};
