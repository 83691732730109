import { useTranslation } from "react-i18next";

import { UnorderedList } from "@src/components/elements/shared/ListComponents";
import BackButton from "@src/components/nav/BackButton";

const Investors = () => {
  const { t } = useTranslation();

  return (
    <>
      <div className="pl-4 pt-4">
        <BackButton stepback desktopVisible />
      </div>
      <div className="grid grid-flow-row gap-6 pb-4 text-gray-700">
        <article className="grid grid-flow-row gap-3 pb-4 text-xs max-xl:px-4">
          <h1>{t("pages.inventors.text01")}</h1>
          <div>
            <h2 className="font-semibold">{t("pages.inventors.text02")}</h2>
            <div>{t("pages.inventors.text03")}</div>
          </div>
          <div>
            <h2 className="font-semibold">{t("pages.inventors.text04")}</h2>
            <UnorderedList
              items={[
                t("pages.inventors.text05.1"),
                t("pages.inventors.text05.2"),
                t("pages.inventors.text05.3"),
                t("pages.inventors.text05.4"),
                t("pages.inventors.text05.5"),
                t("pages.inventors.text05.6"),
              ]}
            />
          </div>
          <div>
            <h2 className="font-semibold">{t("pages.inventors.text06")}</h2>
            <UnorderedList
              items={[
                t("pages.inventors.text07.1"),
                t("pages.inventors.text07.2"),
                t("pages.inventors.text07.3"),
                t("pages.inventors.text07.4"),
              ]}
            />
          </div>
          <div>
            <h2 className="font-semibold">{t("pages.inventors.text08")}</h2>
            <div>{t("pages.inventors.text09")}</div>
          </div>
          <div>
            <div className="flex gap-2">
              <strong>{t("pages.inventors.text10")}</strong> invest@tradefoox.com
            </div>
            <div className="flex gap-2">
              <strong>{t("pages.inventors.text11")}</strong> +49 (0) 371 52 49 99 19 00
            </div>
            <div className="flex gap-2">
              <strong>{t("pages.inventors.text12")}</strong> August-Bebel-Straße 11/13, 09113
              Chemnitz
            </div>
          </div>
        </article>
      </div>
    </>
  );
};

export default Investors;
