import { useState } from "react";
import { useTranslation } from "react-i18next";

import Button from "@src/components/elements/input/Button";
import Input from "@src/components/elements/input/Input";
import { UnorderedList } from "@src/components/elements/shared/ListComponents";
import BackButton from "@src/components/nav/BackButton";

const Contributions = () => {
  const { t } = useTranslation();
  const [selectedAmount, setSelectedAmount] = useState(0);
  const donationItems = [5, 10, 25, 50];

  return (
    <>
      <div className="pl-4 pt-4">
        <BackButton stepback desktopVisible />
      </div>
      <div className="grid grid-flow-row gap-6 pb-4 text-gray-700">
        <article className="grid grid-flow-row gap-3 pb-4 text-xs max-xl:px-4">
          <h1>{t("pages.tradefooxDonations.text01")}</h1>
          <h2 className="font-semibold">{t("pages.tradefooxDonations.text02")}</h2>
          <div>{t("pages.tradefooxDonations.text03")}</div>
          <div>
            <h2 className="font-semibold">{t("pages.tradefooxDonations.text04")}</h2>
            <UnorderedList
              items={[
                t("pages.tradefooxDonations.text05.1"),
                t("pages.tradefooxDonations.text05.2"),
                t("pages.tradefooxDonations.text05.3"),
              ]}
            />
          </div>
          <div>
            <h2 className="font-semibold">{t("pages.tradefooxDonations.text06")}</h2>
            <div className="flex flex-wrap gap-2">
              {donationItems.map(item => (
                <div key={item}>
                  <Button
                    variant={
                      selectedAmount === item ? Button.Variants.Cyan : Button.Variants.Transparent
                    }
                    onClick={() => setSelectedAmount(item)}
                  >
                    {item} €
                  </Button>
                </div>
              ))}
              <Button
                fitWidth
                variant={
                  !donationItems.includes(selectedAmount)
                    ? Button.Variants.Primary
                    : Button.Variants.Transparent
                }
                onClick={() => setSelectedAmount(100)}
              >
                {t("pages.tradefooxDonations.otherValue")}
              </Button>
            </div>
            <div className="flex flex-row items-end gap-1">
              <div className="w-32">
                <Input
                  updateable
                  name="customAmount"
                  value={selectedAmount}
                  onChange={e => {
                    setSelectedAmount(Number(e));
                  }}
                />
              </div>
              <div className="py-1 text-lg">€</div>
            </div>
            <Button fitWidth variant={Button.Variants.Cyan}>
              {t("pages.tradefooxDonations.donateNow")}
            </Button>
          </div>
          <div>{t("pages.tradefooxDonations.text08")}</div>
          <div>
            <h2 className="font-semibold">{t("pages.tradefooxDonations.text09")}</h2>
            <div>{t("pages.tradefooxDonations.text10")}</div>
          </div>
          <div>
            <div className="flex gap-2">
              <div className="font-semibold">{t("pages.tradefooxDonations.account")}</div> day one
              mediagroup gmbh & co. kg (TRADEFOOX)
            </div>
            <div className="flex gap-2">
              <div className="font-semibold">{t("pages.tradefooxDonations.iban")}</div> DE81 8705
              00000 71005 7199
            </div>
            <div className="flex gap-2">
              <div className="font-semibold">{t("pages.tradefooxDonations.bic")}</div> CHEKDE81XXX
            </div>
            <div className="flex gap-2">
              <div className="font-semibold">{t("pages.tradefooxDonations.purpose.1")}</div>
              {t("pages.tradefooxDonations.purpose.2")}
            </div>
          </div>
          <div>
            <h2 className="font-semibold">{t("pages.tradefooxDonations.text11")}</h2>
            <UnorderedList
              items={[
                t("pages.tradefooxDonations.text12.1"),
                t("pages.tradefooxDonations.text12.2"),
                t("pages.tradefooxDonations.text12.3"),
                t("pages.tradefooxDonations.text12.4"),
              ]}
            />
          </div>
          <div>
            <h2 className="font-semibold">{t("pages.tradefooxDonations.text13")}</h2>
            <div className="text-xs">{t("pages.tradefooxDonations.text14")}</div>
          </div>
        </article>
      </div>
    </>
  );
};

export default Contributions;
