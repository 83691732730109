import { useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

import Panel from "@src/components/box/Panel";
import BackButton from "@src/components/nav/BackButton";

type LinkType = {
  href: string;
  label: string;
  children?: Array<LinkType>;
};

const LegalNoticeContainer = () => {
  const { t } = useTranslation();
  const [selection, setSelection] = useState<string>();
  const links: Array<LinkType> = [
    {
      label: t("components.shared.about"),
      href: "",
      children: [
        { label: t("components.shared.aboutUs"), href: "/info" },
        // { label: "TRADEFOOX Blog", href: "" },
        {
          label: t("pages.moreContainer.industriehistory"),
          href: "/industriegeschichte",
        },
        { label: t("pages.newspaper.text7"), href: "/amts_gemeindeblätter" },
        { label: t("pages.events.text1"), href: "/kunst_kultur" },
        { label: t("pages.moreContainer.forStartUps"), href: "/startup_tipps" },
        {
          label: t("pages.moreContainer.trademarklaw"),
          href: "/marken_patentrecht",
        },
        {
          label: t("pages.moreContainer.lookedForSuccessor"),
          href: "/nachfolger",
        },
      ],
    },
    {
      label: t("pages.moreContainer.earnMoney"),
      href: "",
      children: [
        {
          label: t("components.assignments.groupscreate.marketplace"),
          href: import.meta.env.VITE_MARKETPLACE_URL,
        },
        { label: t("pages.moreContainer.partnerProgramm"), href: "/partner" },
        { label: t("pages.moreContainer.rulesForSales"), href: "/agb" },
        // { label: "Händler Verifizierung", href: "" },
        // { label: "Warum Verkäufer auf TRADEFOOX?", href: "" },
        { label: t("pages.actGlobal.global"), href: "/global_agieren" },
      ],
    },
    {
      label: t("pages.moreContainer.weHelpYou"),
      href: "",
      children: [
        { label: t("pages.moreContainer.howItsDone.tradefoox"), href: "/info" },
        {
          label: t("pages.moreContainer.howItsDone.watch"),
          href: "/watch_faq",
        },
        { label: t("pages.moreContainer.faqCompany"), href: "/firmen_faq" },
        { label: t("pages.moreContainer.faqUser"), href: "/mitglieder_faq" },
        {
          label: t("pages.moreContainer.fundraisers"),
          href: "/spendenaktionen",
        },
        { label: t("pages.moreContainer.newsletterSub"), href: "/newsletter" },
        {
          label: t("pages.moreContainer.tradefairCalender"),
          href: "/messe_kalender",
        },
        // { label: "Karriere", href: "" },
        {
          label: t("pages.moreContainer.whyAccount"),
          href: "/account_erstellen",
        },
      ],
    },
    {
      label: t("pages.moreContainer.support"),
      href: "",
      children: [
        {
          label: t("main.profileViewTabs.editProfile.form.moreInformation.termsCondition"),
          href: "/agb",
        },
        {
          label: t("main.profileViewTabs.editProfile.form.startPage.kontakte"),
          href: "/support",
        },
        { label: t("pages.moreContainer.donate"), href: "/spenden" },
        { label: t("pages.moreContainer.investors"), href: "/investoren" },
        { label: t("main.signIn.imprint"), href: "/impressum" },
        { label: t("pages.conditionContainer.text15"), href: "/urheberrecht" },
        { label: t("pages.conditionContainer.text16"), href: "/cookies" },
        { label: t("pages.comRulesContainer.text1"), href: "/community" },
        { label: t("pages.moreContainer.privacyPolicy"), href: "/datenschutz" },
        { label: t("main.signIn.userAgreement"), href: "/nutzungsbedingungen" },
        {
          label: t("pages.moreContainer.newsletterUnsub"),
          href: "/newsletter_abmelden",
        },
        // { label: "Privatsphäre-Einstellungen", href: "" },
      ],
    },
  ];
  return (
    <>
      <BackButton stepback />

      <Panel mobileBorder={false}>
        <div className="grid grid-flow-row gap-6 lg:grid-cols-4 lg:gap-2">
          {links.map((column: LinkType) => {
            return (
              <div key={column.href} className="flex flex-col gap-1 text-xs">
                <NavLink
                  to={column.href}
                  className="font-semibold lg:min-h-[32px]"
                  onClick={() => {
                    setSelection(column.label);
                  }}
                >
                  {column.label}
                </NavLink>
                <div
                  className={`flex flex-col gap-1 text-xs ${selection != column.label && "max-lg:hidden"}`}
                >
                  {column?.children?.map((link: LinkType) => {
                    return (
                      <div
                        key={link.href}
                        className="flex flex-col gap-2 break-words font-normal hover:underline"
                      >
                        <NavLink to={link.href}>{link.label}</NavLink>
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })}
        </div>
      </Panel>
    </>
  );
};

export default LegalNoticeContainer;
